import { environment as base } from "./environment.base";

export const environment = {
  ...base,
  code: 'smrt',
  dashboard: '',
  ga: 'G-CDQ0VPL5F5',
  underlyingInvestmentsChartColors: [
    { "key": "Cash", "value": "#020b12" },
    { "key": "Australian Fixed Interest", "value": "#84c346" },
    { "key": "International Fixed Interest", "value": "#018db2" },
    { "key": "Alternative Defensive", "value": "#cddbe7" },
    { "key": "Australian Shares", "value": "#4cbaea" },
    { "key": "International Shares", "value": "#0ea488" },
    { "key": "Alternative Growth", "value": "#f79820" },
    { "key": "Listed Property", "value": "#12426F"}
  ],
  b2cPolicySignIn: 'B2C_1A_SIGNUP_SIGNIN',
  b2cPolicyElevatedSignIn: 'B2C_1A_MEMBER_ELEVATEDACCESS',
  b2cSignInAuthority:'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
  b2cElevatedSignInAuthority:'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1A_MEMBER_ELEVATEDACCESS',
  b2cAuthorityDomain: 'apexproductdevelopment.b2clogin.com',
  b2cClientId: '87024527-32bc-4252-99ec-64dfc15c3d45',
  b2cReadScope: 'https://ApexProductDevelopment.onmicrosoft.com/4aba6850-0b16-4ea9-9a2f-6ae62bb0d84c/MemberPortal.User',
  b2cWriteScope: 'https://ApexProductDevelopment.onmicrosoft.com/4aba6850-0b16-4ea9-9a2f-6ae62bb0d84c/MemberPortal.User',
  postLogoutRedirectUri:'/'
};


