import { environment as base } from "./environment.smrt";

export const environment = {
  ...base,
  production: true,
  bugsnagEnv:'#{bugsnagEnv}#',
  apiUrl: '#{apiUrl}#',
  b2cApiEndpoint: '#{b2cApiEndpoint}#',
  b2cClientId: '#{b2cClientId}#',
  b2cReadScope: '#{b2cReadScope}#',
  b2cWriteScope: '#{b2cWriteScope}#',
  b2cLogoutUrl: '#{b2cLogoutUrl}#',
  b2cPolicySignIn: '#{b2cPolicySignIn}#',
  b2cSignInAuthority:'#{b2cSignInAuthority}#',
  b2cPolicyElevatedSignIn: '#{b2cPolicyElevatedSignIn}#',
  b2cElevatedSignInAuthority:'#{b2cElevatedSignInAuthority}#',
  b2cAuthorityDomain: '#{b2cAuthorityDomain}#',
  greenIdEnv:'#{greenIdEnv}#',
  greenIdCode:'#{greenIdCode}#',

};
